(function () {
    var timeoutDelay = 500;
    var svcTimeout = null;

    var searchCookieName = function (model) {
        return 'ADP'+model + 'Search';
    }

    var initiateSearch = function(update, value, cookieName) {
        var url = new URL(window.location);
        var page = url.searchParams.get('page');
        var changeHistory = false;
        if (page) {
            url.searchParams.set('page', '1');
            // we want to reset any query page to first page to display start of sort.
           changeHistory = true
        }
        $.get(url.href,function(data) {
            var cookie = COOKIE.get(cookieName);
            if (cookie == value) { // then user hasn't subsequently update results so safe to redraw contents
                if (changeHistory) history.pushState(null, null, url.href);
                $(update).html(data);
            }
        })
    }

    $(document).on('keyup', '[data-search-model]', function () {
        clearTimeout(svcTimeout);
        var that = $(this);
        var model = that.attr('data-search-model');
        var cookieName = searchCookieName(model);
        var update = that.attr('data-search-update');
        var value = that.val();
        if (value) {
            $('#search-submit').addClass('hidden').removeClass('flex');
            $('#search-clear').addClass('flex').removeClass('hidden');
        } else {
            $('#search-clear').addClass('hidden').removeClass('flex');
            $('#search-submit').addClass('flex').removeClass('hidden');
        }
        COOKIE.set(cookieName, value);
        setTimeout(function() {
            initiateSearch(update, value, cookieName)
        }, timeoutDelay)
    }).on('click','#search-clear',function() {
        $('#search-field').val('').trigger('keyup');
        return false;
    }).on(GLOBALS.readyEvent,function() {
        $('[data-search-model]').each(function() {
            var that = $(this);
            var model = that.attr('data-search-model');
            var cookieName = searchCookieName(model);
            var value = COOKIE.get(cookieName)
            that.val(value);
            if (value) {
                $('#search-submit').addClass('hidden').removeClass('flex');
                $('#search-clear').addClass('flex').removeClass('hidden');
            } else {
                $('#search-clear').addClass('hidden').removeClass('flex');
                $('#search-submit').addClass('flex').removeClass('hidden');
            }
        });
    });


})();
