$(document)
    .on(GLOBALS.readyEvent, function () {
        var dp = $(".datepicker")
        dp.datepicker({
            numberOfMonths: 1,
            dateFormat: 'dd/mm/yy',
            showButtonPanel: false,
            maxDate: dp.attr('max'),
            minDate: dp.attr('min')
        });
    })
    .on("ajax:success", "[data-remote][data-update], [data-remote][data-replace]", function (event) {
        var xhr = event.detail[2];
        var that = $(this);
        var els;
        var replacing = that[0].hasAttribute('data-replace');
        var update = replacing ? that.attr('data-replace') : that.attr('data-update');
        if ((update.startsWith('#')) || (that.attr('data-closest') == 'false')) {
            els = $(update);
        } else {
            els = that.closest(update);
        }
        if (replacing) {
            els.replaceWith(xhr.responseText)
        } else {
            els.attr('data-src', xhr.responseURL);
            els.html(xhr.responseText)
            if (els.hasClass('tab')) {
                els.show().siblings().hide();
            }
        }
    })
    .on("ajax:success", "[data-remote][data-remove]", function (event) {
        var that = $(this);
        var els;
        if (that.attr('data-closest') == 'false') {
            els = $(that.attr('data-remove'));
        } else {
            els = that.closest(that.attr('data-remove'));
        }
        els.remove();
    })
    .on("ajax:success", "[data-remote][data-success-message]", function (event) {
        var that = $(this);
        alert(that.attr('data-success-message'));
    })
    .on("ajax:success", "[data-remote][data-success-goto]", function (event) {
        var that = $(this);
        document.location = that.attr('data-success-goto');
    })
    .on('click', '[data-href]', function (e) {
        var target = $(e.target);
        if (target.closest('a,button,input').length > 0) return true;
        if ((target.prop('tagName') == 'LABEL') && (target.attr('for'))) return true;  //was a click on label will repropagate with checkbox
        console.log('event', e)
        var that = $(this);
        var href = that.attr('data-href');
        var tb = that.closest('[data-turbolinks]');
        var useTurbolinks = tb.attr('data-turbolinks') == 'false' ? false : true;
        useTurbolinks ? Turbolinks.visit(href) : document.location = href;
        return false;
    })
    .on('click', '[data-search]', function () {
        var that = $(this);
        var query = that.attr('data-search');
        $('.search-field').val(query).trigger('keyup');
        return false;
    });
