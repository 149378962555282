(function () {

    Array.prototype.unique = function () {
        return this.filter(function (value, index, self) {
            return self.indexOf(value) === index;
        });
    }

    Array.prototype.difference = function (a) {
        return this.filter(function (i) {
            return a.indexOf(i) < 0;
        });
    };

    var selectionCookieName = function (model) {
        return 'ADP'+model + 'Selected';
    }

    var updateSelection = function ($el, combinator) {
        var model = $el.attr('data-model');
        var id = $el.attr('data-id').split('|');
        var ids = Array.isArray(id) ? id : [id];
        var cookieName = selectionCookieName(model);
        var cookie = COOKIE.get(cookieName)
        var existing = cookie ? cookie.split('|') : [];
        var all = combinator(existing, ids);
        COOKIE.set(cookieName, all.join('|'));
        $el.closest('.select-group').find('.select-count').html(all.length);
        return all;
    }

    var selectRecord = function ($el) {
        return updateSelection($el, function (existing, ids) {
            return ids.concat(existing).unique();
        });
    }

    var deselectRecord = function ($el) {
        return updateSelection($el, function (existing, ids) {
            return existing.difference(ids);
        });
    }

    var clearSelection = function ($el) {
        return updateSelection($el, function (existing, ids) {
            return [];
        });
    }

    var getSelection = function ($el) {
        return updateSelection($el, function (existing, ids) {
            return existing;
        });
    }

    var highlight = function (el) {
        var that = $(el);
        var tr = that.closest('tr, .tr');
        if (el.checked) {
            tr.addClass('selected')
        } else {
            tr.removeClass('selected')
        }
    }

    var redraw = function () {
        var groups = $('.select-group');
        groups.each(function () {
            var group = $(this);
            var els = group.find('.multi-record-select');
            if (els.length > 0) {
                var all = getSelection(els);
                els.each(function () {
                        var that = $(this);
                        var id = '' + that.attr('data-id');
                        if (all.indexOf(id) >= 0) {
                            this.checked = true;
                        } else {
                            this.checked = false;
                        }
                        highlight(this);
                    }
                )
            }
        });
    }

    $(document).on('click', '.multi-record-select', function () {
        // toggle id in cookie
        var that = $(this);
        var list = this.checked ? selectRecord(that) : deselectRecord(that);
        highlight(this);
    });

    $(document).on('click', '.select-all', function () {
        var that = $(this);
        var ids = that.attr('data-id');
        if (ids) {
            selectRecord(that);
        } else {
            clearSelection(that);
        }
        redraw();
        return false;
    });

    $(document).on(GLOBALS.readyEvent, function () {
        redraw();
    }).on("ajaxSuccess", function (e) {
        redraw();
    });

})();