(function () {

    var sortCookieName = function (model) {
        return "ADP" +model + 'Sort';
    }


    $(document).on('click', '[data-sort-column]', function () {
        var that = $(this);
        var col = that.attr('data-sort-column');
        var table = that.attr('data-sort-table');
        var update = that.attr('data-sort-update');
        var cookieName = sortCookieName(table);
        var cookie = COOKIE.get(cookieName);
        if (cookie == col) col += ' desc';
        COOKIE.set(cookieName, col);
        var url = new URL(window.location);
        var tableReg = new RegExp(table,'i');
        if (!tableReg.test(url.href)) {
            url.searchParams.set('table', table);
        }
        var page = url.searchParams.get('page');
        if (page) {
            url.searchParams.set('page', '1');
            // we want to reset any query page to first page to display start of sort.
            history.pushState(null, null, url.href);
        }
        $.get(url.href, function (data) {
            $(update).html(data);
        });
        return false;
    });

})
();